<template>
  <div class="v_my_team g-flex-column">
    <head-back>
      <template v-slot:returnText>
        <span>返回</span>
      </template>

      <template v-slot:default>
        <span>我的团队</span>
      </template>
    </head-back>
    
    <div class="v-my-team-container g-flex-column">

      <ul class="v-my-team-head-list g-flex-align-center">
        <li class="v-my-team-head-list-item g-flex-align-center g-flex-justify-center">
          <span class="v-my-team-head-list-item-title">昵称</span>
        </li>
         <li class="v-my-team-head-list-item g-flex-align-center g-flex-justify-center">
          <span class="v-my-team-head-list-item-title">手机号码</span>
        </li>
         <li class="v-my-team-head-list-item g-flex-align-center g-flex-justify-center">
          <span class="v-my-team-head-list-item-title">加入时间</span>
        </li>
        <li class="v-my-team-head-list-item g-flex-align-center g-flex-justify-center">
          <span class="v-my-team-head-list-item-title">团队</span>
        </li>
        <li class="v-my-team-head-list-item g-flex-align-center g-flex-justify-center">
          <span class="v-my-team-head-list-item-title">佣金</span>
        </li>
      </ul>
      <div class="v-my-team-list-container">
          <van-list class="v-my-team-list"  v-show="teamList.length != 0" v-model="loading" :finished="finished"  finished-text="没有更多了"  @load="onLoad" :immediate-check="false">
            <div class="v-my-team-list-item g-flex-column" v-for="(item, index) in teamList" :key="index">
              <div class="v-my-team-list-item-box g-flex-align-center">
                <p class="v-my-team-list-item-val g-flex-align-center g-flex-justify-center">{{item.nick_name}}</p>
                <p class="v-my-team-list-item-val g-flex-justify-center">{{ item.mobile }}</p>
                <p class="v-my-team-list-item-val g-flex-justify-center">{{formatDate(item.create_time, 'yyyy-MM-dd')}}</p>
                <p class="v-my-team-list-item-val g-flex-justify-center">{{item.spread_nums}}</p>
                <p class="v-my-team-list-item-val g-flex-justify-center">{{item.money}}</p>
              </div>
              <div class="v-my-team-list-item-btn g-flex-align-center g-flex-justify-center" @click="seeSecondClick(item)">
                <span>查看团队</span>
              </div>
            </div>
          </van-list>

          <NoList v-show="teamList.length == 0"/> 
      </div>
    </div>

  </div>
</template>

<script>
  import HeadBack from '../../components/HeadBack.vue'
  import { PAGE, LIMIT } from '@/utils/constants.js'
  import { apiGetSpreadList } from '@/utils/api.js'
  import { formatDate } from '../../utils/index.js'
  export default {
    components: { HeadBack },
    data() {
      return {
        form: {
          type: 1,
          user_id: '',
          page: PAGE,
          limit: LIMIT + 5,
        },
        loading: false,
        finished: false,
        teamList: []
      }
    },
    created() {
      this.apiGetSpreadListHandel()
    },
    methods: {
      formatDate,
      onLoad() {
        this.form.page++
        this.apiGetSpreadListHandel()
      },
      // 获取列表
      async apiGetSpreadListHandel() {
        const { success, data } = await apiGetSpreadList(this.form)
        if(!success) return
        this.teamList = this.teamList.concat(data.list)
        this.loading = false
        this.finished = data.list.length < this.form.limit
      },
      
      seeSecondClick({ user_id }) {
        this.$router.push({ name: 'myteamsecond', params: { id: user_id } })
      },

    }
  }
</script>

<style lang="scss">
.v_my_team {
  height: 100%;
  background: #F1F1F1;
  .v-my-team-container {
    flex: 1;
    overflow: auto;
    .v-my-team-head-list {
      padding: 16px 10px;
      background: $main_color;
      .v-my-team-head-list-item {
        flex: 1;
        color: $white;
        font-size: 12px;
      }
    }
    .v-my-team-list-container {
        flex: 1;
        overflow: auto;
       .v-my-team-list {
         padding: 0 10px;
         background: #F1F1F1;
         .v-my-team-list-item {
            margin-top: 10px;
            border-radius: 8px;
            background: $white;
            padding-bottom: 10px;
           .v-my-team-list-item-box {
            padding: 14px 0;
            .v-my-team-list-item-val {
              text-align: center;
              font-size: 12px;
              flex: 1;
              @include textManyOverflow();
            }
           }
           .v-my-team-list-item-btn {
             margin-right: 20px;
             align-self: flex-end;
             span {
              color: $white;
              border-radius: 12px;
              padding: 5px 14px;
              background: $main_color;
              font-size: 12px;
              vertical-align: middle;
             }
           } 
         }
      }
    }
  }
}
</style>