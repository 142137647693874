<template>
  <div class="c_head_back">
    <div class="c-head-content g-flex-align-center" :style="{ 'backgroundColor': bgColor }">

      <div class="c-head-left g-flex-align-center" :style="{ 'color': fontColor }">

        <div class="c-head-left-click g-flex-align-center" v-if="isBack" @click="backClick">
          <i class="iconfont icon-back"></i>
          <slot name="returnText"></slot>
        </div>
  
      </div>
      
        <div class="c-head-title g-flex-align-center g-flex-justify-center" :style="{ 'color': fontColor }">
          <slot>
          </slot>
        </div>
      
      
        <div class="c-head-right g-flex-align-center g-flex-justify-end" :style="{ 'color': fontColor }">
          <slot name="returnRight">
          </slot>
        </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      // 后退的路由名称
      backRouterName: {
        type: String,
        default() {
          return ''
        }
      },
      // 是关闭组件还是路由返回
      isRouterBack: {
        type: Boolean,
        default() {
          return true
        }
      },
      // 是否需要展示后退
      isBack: {
        type: Boolean,
        default() {
          return true
        }
      },
      // 背景颜色
      bgColor:{
        type: String,
        default() {
          return '#FFFFFF'
        }
      },
      // 字体颜色
      fontColor: {
        type: String,
        default() {
          return '#F9436B'
        }
      }
    },
    methods: {
      backClick() {
        if(this.backRouterName) return this.$router.push({ name: this.backRouterName })
        if(this.isRouterBack) return this.$router.go(-1)
        this.$emit('emitHeadBack')
      },
    }
  }
</script>

<style lang="scss">
.c_head_back {
  .c-head-content {
    padding: 12px 14px;
    .c-head-left, .c-head-title, .c-head-right {
      flex: 1;
    }

    .c-head-left {
      word-break: break-all;
      .c-head-left-click {
        .iconfont {
          font-size: 20px;
          padding-right: 8px;
        }
        span {
          font-size: 14px;
        }
      }
    }
    .c-head-title {
      font-size: 14px;
      word-break: break-all;
    }
    .c-head-right {
      font-size: 14px;
    }
  }
}
</style>